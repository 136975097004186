import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';


import ERImage from "../images/IMAGEM_2_1.png";
import CodeImage from "../images/IMAGEM_1.png";
import CovidImage from "../images/IMAGEM_3.png";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingBottom: theme.spacing(6),
        backgroundColor: theme.palette.secondary.dark
    },
    grid: {
        //height: 500
    },
    imageBox: {
        //maxHeight: 500,
        borderRadius: 0,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
      title: {
        color: '#fff',
        fontSize: 24,
        fontWeight: 700,
        marginTop: theme.spacing(4)
      },
      buttonUnderline: {
        backgroundColor: '#fff',
        height: 14,
        width: 50,
        margin: 'auto',
        marginTop: theme.spacing(3)
      },

    intro: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 500,
        marginTop: theme.spacing(4),
        textAlign: 'justify',
        textAlignLast: 'justify'
      },
      introBox: {
        maxWidth: 644,
        margin: 'auto',
        padding: theme.spacing(4),
      },
}))


export default function MissionSection(props) {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid className={classes.grid} container>
                <Grid item xs>
                <Card className={classes.imageBox} elevation={2}>
                        <CardMedia
                            className={classes.media}
                            image={CodeImage}
                        />
                    </Card>
                </Grid>
                <Grid item xs>
                    <Card className={classes.imageBox} elevation={2}>
                        <CardMedia
                            className={classes.media}
                            image={ERImage}
                        />
                    </Card>
                </Grid>
                <Grid item xs>
                    <Card className={classes.imageBox} elevation={2}>
                        <CardMedia
                            className={classes.media}
                            image={CovidImage}
                        />
                    </Card>
                </Grid>
            </Grid>

        <Typography className={classes.title}>MISSION</Typography>
        <Grow timeout={1000} in={props.render}><div className={classes.buttonUnderline} /></Grow>
        <div className={classes.introBox}>
        <Typography className={classes.intro}>
        Our mission is to simplify and improve patient access to healthcare.<br/>
        In order to do that we focus on reducing nonclinical tasks burden, building the best technology tools to increase self-service journeys, which provides new and easy ways to access medical care. This enables patients to actively manage their health.<br/>
        As a complement we provide hospitals and clinics with administrative support efficient tools, seamless integrated with self-service channels to ensure consistent services and information along with semi digital journeys.
        </Typography>
        </div>
        </div>
    )
}